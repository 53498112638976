import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import About from './components/About';
import Contact from './components/contactUs';
import Footer from './components/Footer';
import Header from './components/Header';
import Heizung from './components/Heizung';
import Index from './components/index';
import Sanitar from './components/Sanitar';
import Services from './components/Service';
import Wartung from './components/Wartung';



function App() {
  return (
    
    <Router>
      <div className="App">
        
        <Header />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/notdienst" element={<About />} />
          <Route path="/bäder" element={<Services />} />
          <Route path="/heizungsanlage" element={<Footer />} />
          <Route path="/weißhaupt" element={<Index />} />
          <Route path="/pfullingen" element={<Contact />} />
          <Route path="/heizung" element={<Heizung />} />
          <Route path="/sanitär" element={<Sanitar />} />
          <Route path="/reutlingen" element={<Wartung />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
